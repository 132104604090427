import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { sha256 } from 'js-sha256';
import { useMediaQuery, useTheme } from '@mui/material';
import { HeaderContainer, HeaderContentContainer, HeaderGnbContainer, HeaderTopBannerContainer } from '@components/layout/Header/Header.styles';
import { useUserInfo } from '@hooks';
import { GET_HEADER_INFO } from '@api';
import useSettings from '@hooks/useSettings';
import { PAGE_TYPE } from 'src/constants';
import { CommonContext, UserInfoStatusEnum } from 'src/provider';
import { throttleWrapper } from '@util/eventHandling';
import { convertLangCodeForDB } from '@lib/index';
import { BAND_BANNER_COOKIE_KEY, GA_ACON_USER_ID_COOKIE_KEY } from './Header.constants';
import Gnb from './components/Gnb/Gnb';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import { SubGnb } from './components/subGnb';
import { BandBanner } from './components/BandBanner';

const Header = (): React.ReactElement => {
  const [isScrolled, setIsScrolled] = useState(false);
  // 번역도구
  const { i18n } = useTranslation();
  // 쿠키들
  const [cookies, setCookie, removeCookie] = useCookies();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: false });
  const language = convertLangCodeForDB(i18n.language);

  const { userInfo, status } = useUserInfo();
  const { clips, carts, cartsOnSession } = useContext(CommonContext);

  const cartsCount = useMemo(() => (status === UserInfoStatusEnum.authenticated ? carts : cartsOnSession)?.length, [status, carts, cartsOnSession]);

  const [isShowExplore, setIsShowExplore] = useState(false);
  const [hasMounted, setHasmounted] = useState(false);
  const { themeMode } = useSettings();

  // 쿠키에 저장된 배너 숨김 정보
  const bannerCookie = cookies[BAND_BANNER_COOKIE_KEY] || {};

  // 밴드 배너 데이터 조회
  const { data: headerInfoItem } = useQuery(GET_HEADER_INFO, { variables: { lang: language } });
  // 배너 데이터
  const bandBannerData = headerInfoItem?.getBandBannerForPage.filter((e) => {
    const hideUntil = bannerCookie[e.id]?.hideUntil;
    return !hideUntil || hideUntil < Date.now();
  });

  const bannerHeight = isMobile ? 0 : (hasMounted ? bandBannerData?.length || 0 : 0) * 44;
  const bannerHeightAuto = themeMode === PAGE_TYPE.TOON ? bannerHeight + 128 - (isMobile && 42) : bannerHeight + 80;
  const top = isScrolled ? (isMobile ? 50 : 78 + bannerHeight) : 0;

  // 파라미터에 배너 id를 입력하면 해당 id의 쿠키를 저장하는 callback을 리턴합니다.
  const genRemoveCallbackByBannerId = (id) => {
    // 밴드 배너 항목을 삭제하는 이벤트 처리기 메소드입니다.
    return (e) => {
      e.preventDefault();
      // 쿠키 만료일자
      const expiry = new Date();
      // 쿠키 만료일자를 오늘일자 YYYY-MM-DDT23:59:59 로 세팅
      expiry.setHours(23, 59, 59, 999);

      bannerCookie[id] = { hideUntil: expiry };
      setCookie(BAND_BANNER_COOKIE_KEY, bannerCookie, { path: '/' });
    };
  };

  // 현재 스크롤 Y 값을 가져오는 메소드입니다.
  const getScrollY = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  const handleScroll = throttleWrapper(() => setIsScrolled(Boolean(getScrollY() > 0)), 0);

  // useEffect(() => console.log(bandBannerData), [bandBannerData]);
  const bandBanners = bandBannerData?.map((data, i) => {
    // if (isMobile) return <Fragment key={i} />;
    return <BandBanner key={i} isMobile={isMobile} onClickRemove={genRemoveCallbackByBannerId(data.id)} {...data} />;
  });

  // kakao
  const isKakaoScriptLoad = typeof window !== 'undefined' && Boolean(window.Kakao);

  useEffect(() => {
    const jsKey = '5b58fea3856a0c411926e0340a61626d';

    // SDK는 한 번만 초기화해야 한다.
    // 중복되는 초기화를 막기 위해 isInitialized()로 SDK 초기화 여부를 판단한다.
    if (isKakaoScriptLoad && !window.Kakao?.isInitialized()) {
      // JavaScript key를 인자로 주고 SDK 초기화
      window.Kakao.init(jsKey);
    }
  }, [isKakaoScriptLoad]);

  useEffect(() => {
    if (userInfo) {
      datadogRum.setUser({
        id: userInfo.userId,
      });
      // GTM 에서 사용하는 유저 식별 정보 쿠키 설정
      setCookie(GA_ACON_USER_ID_COOKIE_KEY, sha256(userInfo.userId), { path: '/' });
    } else {
      // GTM 에서 사용하는 유저 식별 정보 쿠키 삭제 (유저 정보가 없으면 로그인 상태가 아닌 걸로 간주)
      removeCookie(GA_ACON_USER_ID_COOKIE_KEY, { path: '/' });
    }
  }, [userInfo?.userId]);

  useEffect(() => {
    setHasmounted(true);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer bannerHeight={bannerHeightAuto - top}>
      <HeaderContentContainer id="gnb" top={themeMode === PAGE_TYPE.TOON ? -top : 0}>
        <HeaderTopBannerContainer isMobile={isMobile}>
          <UnsupportedBrowser />
          {hasMounted && Boolean(bandBannerData?.length) && bandBanners}
        </HeaderTopBannerContainer>
        <HeaderGnbContainer>
          <Gnb
            wishCount={clips?.length}
            cartCount={cartsCount}
            isCoupon={false}
            isMobile={isMobile}
            isLogin={status === UserInfoStatusEnum.authenticated}
            isLoginLoading={status === UserInfoStatusEnum.loading}
          />
          {themeMode === 'toon' && <SubGnb isMobile={isMobile} setIsShowExplore={setIsShowExplore} isShowExplore={isShowExplore} />}
        </HeaderGnbContainer>
      </HeaderContentContainer>
    </HeaderContainer>
  );
};

export default Header;
