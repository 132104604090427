import styled from 'styled-components';
import { NoBorderInput } from '@components/ui/Input';
import { FlexRowAlignCenter } from '@components/wrapper';
import { colors } from '@public/theme';

export const SearchBox = styled(FlexRowAlignCenter)`
  background-color: #f8f8f8;
  border: 1px solid ${colors.gray.c1};
  padding: 0px 20px;
  border-radius: 17px;
  height: 34px;
  width: 380px;
  position: relative;
  text-align: left;
  justify-content: space-between;
`;

export const SearchInput = styled(NoBorderInput)`
  flex: 1;
  margin-right: 20px;
  background-color: transparent;
`;

export const Placeholder = styled.div<{ paddingTop: number }>`
  pointer-events: none;
  position: absolute;
  font-size: 14px;
  color: #7c7c7c;
  line-height: 20px;
  padding-top: ${(props) => `${props.paddingTop}px`};
`;
