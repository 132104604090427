import React from 'react';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNotification } from '@hooks/notification';
import { CustomLink } from '@components/ui';
import { PATH_FAQ, PATH_MYPAGE } from 'src/routes/paths';
import { MyPageContainer, MyPageContents, MyPageItem, MyPageItemLink, MyPageList } from '@components/layout/Header/components/MyPage/MyPage.styles';
import { CommonContext } from 'src/provider';
import { LANGUAGE_CODE, NOTIFICATION_CODE } from 'src/constants';
import { IMyPageProps } from './MyPage.types';

const MyPage = ({ isCoupon, onClose }: IMyPageProps): React.ReactElement => {
  const { t, i18n } = useTranslation(['menu']);
  const [findNoti] = useNotification();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const router = useRouter();
  const { removeAuthToken } = useContext(CommonContext);

  const handleLogout = async (e) => {
    e.preventDefault();

    await axios
      .post(process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/logout', null, {
        withCredentials: true,
      })
      .then(() => {
        return router.push('/').then(() => {
          removeAuthToken();
        });
      });
  };
  return (
    <MyPageContainer width={i18n.language === LANGUAGE_CODE.JP ? 140 : 86} onClick={onClose}>
      <MyPageContents>
        <MyPageList>
          {!isDesktop && (
            <MyPageItem>
              <CustomLink href="/mypage/clip-book" className="noti_text">
                <MyPageItemLink>{t('menu:gnb.scrap')}</MyPageItemLink>
              </CustomLink>
            </MyPageItem>
          )}
          <MyPageItem>
            <CustomLink href="/mypage" className="noti_text">
              <MyPageItemLink isNoti={isCoupon || Boolean(findNoti(NOTIFICATION_CODE.ACON_CASH))}>{t('menu:subgnb.mypage.userHome')}</MyPageItemLink>
            </CustomLink>
          </MyPageItem>
          <MyPageItem>
            <CustomLink href={PATH_MYPAGE.orderList} className="menu__orders">
              <MyPageItemLink>{t('menu:subgnb.mypage.orders')}</MyPageItemLink>
            </CustomLink>
          </MyPageItem>
          <MyPageItem>
            <CustomLink href={PATH_FAQ.root} className="menu__support">
              <MyPageItemLink>{t('menu:subgnb.mypage.support')}</MyPageItemLink>
            </CustomLink>
          </MyPageItem>
          <MyPageItem>
            <a className="menu__signout" onClick={(e) => handleLogout(e)}>
              <MyPageItemLink>{t('menu:subgnb.mypage.logout')}</MyPageItemLink>
            </a>
          </MyPageItem>
        </MyPageList>
      </MyPageContents>
    </MyPageContainer>
  );
};

export default MyPage;
