import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import { CustomLink, CustomText, Skeleton } from '@components/ui';
import Logo from 'src/assets/Logo';
import useSettings from '@hooks/useSettings';
import { LANGUAGE_CODE, PAGE_TYPE } from 'src/constants';
import { GNBTab } from '@components/header/tab';
import LogoBeta from 'src/assets/LogoBeta';
import GnbScrap from 'src/assets/GnbScrap';
import GnbCart from 'src/assets/GnbCart';
import GnbUser from 'src/assets/GnbUser';
import GnbUserArrow from 'src/assets/GnbUserArrow';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import { Mypage } from './mypage';
import { Search } from './search';
import { Container, Div, RightButtons, Wrapper } from './styled';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const Gnb = ({ isLogin, isLoginLoading, isCoupon, cartCount }) => {
  // 번역 도구
  const { t, i18n } = useTranslation(['game', 'menu', 'mode']);
  // 마이페이지 메뉴 표시 여부
  const [isMypageDisplay, setIsMypageDisplay] = useState(false);
  // 마이페이지 영역
  const mypageRef = useRef(null);
  const { themeMode } = useSettings();
  const theme = useTheme();

  return (
    <Wrapper height={themeMode === PAGE_TYPE.TOON ? 100 : 80}>
      <Container>
        <Div>
          {[LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE) ? (
            <>
              <CustomLink href={`/${themeMode || PAGE_TYPE.TOON}`}>
                <Logo />
              </CustomLink>
              {[PAGE_TYPE.TOON, PAGE_TYPE.GAME].map((mode) => {
                const isActive = mode === themeMode;
                return (
                  <CustomLink key={mode} href={`/${mode}`}>
                    <CustomText
                      className="ml-8"
                      size={16}
                      weight={600}
                      lineHeight={'24px'}
                      color={isActive ? theme.colors.activeText : theme.colors.text}
                      data-mixpanel-action="click"
                      data-mixpanel-evt={`${mode === PAGE_TYPE.TOON ? 'Toon' : 'Game'} Tab Click`}
                    >
                      {t(`mode:${mode}`)}
                    </CustomText>
                  </CustomLink>
                );
              })}
            </>
          ) : (
            <CustomLink href={'/'}>
              <LogoBeta />
            </CustomLink>
          )}
          {themeMode === PAGE_TYPE.TOON && <Search className="ml-8" />}
        </Div>
        <nav>
          <RightButtons>
            {themeMode === PAGE_TYPE.TOON && (
              <GNBTab size={14} lineHeight={'20px'} marginLeft={0} style={{ marginRight: '24px' }} className="menu__about" displayName={t('menu:gnb.about')} href="/intro" />
            )}
            {themeMode === PAGE_TYPE.GAME && (
              <HoverItem>
                <CustomLink className={'mr-8'} href={`/${themeMode}/intro/3d-creator`}>
                  <div className={'flex items-center'}>
                    <GnbHome className={'mr-1'} />
                    <CustomText weight={400} color={theme.colors.activeText}>
                      {t('game:upload')}
                    </CustomText>
                  </div>
                </CustomLink>
              </HoverItem>
            )}
            {isLoginLoading ? (
              <Skeleton width={'150px'} height={'14px'} marginLeft={10} />
            ) : isLogin ? (
              <>
                <HoverItem>
                  <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/clip-book'}>
                    <GnbScrap />
                  </CustomLink>
                </HoverItem>
                <HoverItem>
                  <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/cart'}>
                    <GnbCart count={cartCount} />
                  </CustomLink>
                </HoverItem>
                <div
                  className={'flex items-center justify-center relative cursor-pointer'}
                  ref={mypageRef}
                  onMouseOver={() => setIsMypageDisplay(true)}
                  onMouseOut={() => setIsMypageDisplay(false)}
                >
                  <HoverItem>
                    <GnbUser />
                    <GnbUserArrow isOpen={isMypageDisplay} />
                  </HoverItem>
                  {isMypageDisplay && <Mypage isCoupon={isCoupon} onClose={() => setIsMypageDisplay(false)} />}
                </div>
              </>
            ) : (
              <>
                <HoverItem>
                  <CustomLink className={'mr-4 cursor-pointer'} href={'/mypage/cart'}>
                    <GnbCart count={cartCount} isLogin={isLogin} />
                  </CustomLink>
                </HoverItem>
                <GNBTab size={14} marginLeft={0} displayName={t('menu:gnb.signin')} href={`/${i18n.language}/users/login`} />
                <CustomText size={14} marginLeft={5} color={theme.colors.activeText}>
                  /
                </CustomText>
                <GNBTab size={14} displayName={t('menu:gnb.join')} href={'/users/join'} marginLeft={5} />
              </>
            )}
          </RightButtons>
        </nav>
      </Container>
    </Wrapper>
  );
};
