import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { LANGUAGE_CODE } from 'src/constants';

export interface ICustomTextProps {
  tagName?: string;
  size?: number | null;
  color?: string | null;
  bold?: boolean;
  medium?: boolean;
  weight?: 400 | 500 | 600 | 700;
  letter?: number;
  style?: any;
  lineHeight?: string;
  numLines?: number;
  marginLeft?: number;
  className?: string;
  children?: ReactNode | string[] | number[];
  autoLetter?: boolean;
  verticalAlign?: string;
  textAlign?: string;
  onClick?: (...arg) => void;
  [key: string]: any;
  letterSpacing?: string;
}

const CustomText: FC<ICustomTextProps> = ({
  tagName = 'div',
  size = 14,
  color = '#333333',
  letter = 0.0,
  letterSpacing = 'normal',
  autoLetter = false,
  bold,
  medium,
  weight,
  lineHeight = '100%',
  numLines,
  style,
  marginLeft,
  className = '',
  children,
  verticalAlign,
  textAlign,
  onClick,
  ...rest
}): React.ReactElement => {
  const { i18n } = useTranslation();
  // 한국어 여부
  const isKo = i18n.language === LANGUAGE_CODE.KO;

  const styleObj: any = {
    fontSize: size,
    color: color,
    fontWeight: weight ? weight : bold ? 700 : medium ? 500 : 400,
    ...(!autoLetter ? letter && { letterSpacing: `${letter}em` } : isKo && { letterSpacing: '-0.01em' }),
    lineHeight: lineHeight,
    marginLeft: marginLeft,
    verticalAlign,
    textAlign,
    ...(numLines && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: numLines,
      WebkitBoxOrient: 'vertical',
      letterSpacing: letterSpacing,
    }),
    ...style,
  };

  // 커스텀 태그
  // eslint-disable-next-line no-undef
  const CustomTag = `${tagName}` as keyof JSX.IntrinsicElements;

  return (
    <CustomTag style={styleObj} {...rest} className={className} onClick={onClick}>
      {children}
    </CustomTag>
  );
};

export { CustomText };
