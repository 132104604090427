import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';
import { CustomIcon } from '@components/ui/Icon';
import { CustomLink, CustomText } from '@components/ui';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import { PATH_PHOTO_REVIEW, PATH_FUNDING } from 'src/routes/paths';
import Grid from '@components/layout/Grid/Grid';
import { SubGNBTab } from '../tab';
import { Category } from './category';
import * as Styled from './styled';
import CategoryIcon from 'src/assets/icons/CategoryIcon';

interface props {
  isMobile: boolean;
  isShowExplore: boolean;
  setIsShowExplore: React.Dispatch<any>;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const SubGnb: React.FC<props> = ({ isMobile, isShowExplore, setIsShowExplore }) => {
  const router = useRouter();
  // 번역 도구
  const { t, i18n } = useTranslation(['game', 'menu']);

  // 3D모델 찾기 버튼 클릭 이벤트 처리기 메소드
  const handleExploreButtonClick = () => {
    if (!isMobile) {
      // 모달창 표시
      setIsShowExplore(true);
      // 종료
    }
  };

  // 카테고리 닫기
  const handleCategoryClose = () => {
    setIsShowExplore(false);
  };

  // 카테고리 컴포넌트 제거 메소드
  const handleCategoryRemoveClick = () => {
    // 3D 모델찾기 모달 숨김
    location.hash = '#';
    handleCategoryClose();
  };

  // hash 변경 감지
  useEffect(() => {
    // URL에 #category가 있을 경우 모델 표시
    if (!isShowExplore && location.hash === '#category') {
      // 3D 모델찾기 모달 표시
      setIsShowExplore(true);
    }
  }, [typeof location !== 'undefined' && location.hash]);

  return (
    <Styled.Wrapper isMobile={isMobile}>
      <Styled.Container>
        <Grid>
          <Grid.Item>
            <Stack direction="row" justifyContent="flex-start" alignItems={isMobile ? 'center' : 'flex-start'} flexGrow={1} gap={isMobile ? '20px' : '56px'}>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <HoverItem isMobile={isMobile} className={'h-full'}>
                  <Styled.CategoryButton
                    onClick={handleExploreButtonClick}
                    data-mixpanel-action="click"
                    data-mixpanel-evt="GNB Click, GNB Click_category"
                    data-mixpanel-name="category"
                  >
                    <SubGNBTab
                      // prefetch 위한 코드
                      href={null}
                      isMobile={isMobile}
                      iconComponent={<CategoryIcon size={24} />}
                      iconMargin={8}
                      className="gnb__category whitespace-pre"
                      displayName={t('menu:subgnb.findModel')}
                    />
                  </Styled.CategoryButton>
                </HoverItem>
              </Box>
              <Category isShow={isShowExplore} isMobile={false} onClick={handleCategoryRemoveClick} onClose={handleCategoryClose} />

              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.pathname === '/toon/event'}>
                  <SubGNBTab isMobile={isMobile} className="gnb__event whitespace-pre" displayName={t('menu:subgnb.event')} href="/event" />
                </Styled.CommonLi>
              </HoverItem>

              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.pathname === '/toon/best'}>
                  <SubGNBTab isMobile={isMobile} className="gnb__best whitespace-pre" displayName={t('menu:subgnb.best')} href="/best" />
                </Styled.CommonLi>
              </HoverItem>

              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.pathname === '/toon/new'}>
                  <SubGNBTab isMobile={isMobile} className="gnb__new whitespace-pre" displayName={t('menu:subgnb.new')} href="/new" />
                </Styled.CommonLi>
              </HoverItem>
              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.pathname === '/toon/event/only-acon'}>
                  <SubGNBTab isMobile={isMobile} className="gnb__hotoffer whitespace-pre" isBadge={false} displayName={t('menu:subgnb.onlyAcon')} href="/event/only-acon" />
                </Styled.CommonLi>
              </HoverItem>
              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.pathname === PATH_PHOTO_REVIEW.root}>
                  <SubGNBTab
                    isMobile={isMobile}
                    displayName={t('menu:subgnb.photoreview')}
                    className="gnb__photoreviews whitespace-pre"
                    href={PATH_PHOTO_REVIEW.root}
                    isBadge={false}
                  />
                </Styled.CommonLi>
              </HoverItem>

              {/** [todo]: 실서버에서 펀딩 QA를 진행하기 위해 임시 주석처리 */}
              <HoverItem isMobile={isMobile} className={'h-full'}>
                <Styled.CommonLi isActive={router.asPath.indexOf(PATH_FUNDING.root) > -1}>
                  <SubGNBTab
                    isMobile={isMobile}
                    isBadge={new Date() <= new Date('2022-12-31T23:59:59+09:00')}
                    displayName={t('menu:subgnb.funding')}
                    className="gnb__funding whitespace-pre"
                    href={PATH_FUNDING.root}
                  />
                </Styled.CommonLi>
              </HoverItem>

              <HoverItem isMobile={isMobile} className={`h-full ${isMobile ? '' : 'ml-auto'}`}>
                <CustomLink className={'menu__upload gnb__upload'} href={'/intro/3d-creator'}>
                  <div className={'flex items-center'}>
                    {!isMobile && <GnbHome className={'mr-1'} />}
                    <CustomText className="whitespace-pre" weight={isMobile ? 500 : 600} color={'inherit'} lineHeight={isMobile ? '100%' : '20px'}>
                      {t('game:upload')}
                    </CustomText>
                  </div>
                </CustomLink>
              </HoverItem>
            </Stack>
          </Grid.Item>
        </Grid>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
