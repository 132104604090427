import React, { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Header from '@components/layout/Header/Header';
import Footer from '@components/layout/Footer/Footer';
import { Header as OldHeader } from '@components/header';
import { Footer as OldFooter } from '@components/footer';
import GlobalStyle from '@styles/globalStyles';

type Props = {
  children: ReactNode;
};

const EXEPTION_HEADERS = ['/toon/event/only-acon', '/toon'];

export default function Layout({ children }: Props) {
  const { i18n } = useTranslation();
  const router = useRouter();
  const isNewHeader = EXEPTION_HEADERS.includes(router.pathname);
  return (
    <>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore */}
      <GlobalStyle lang={i18n.language} />
      {isNewHeader ? <Header /> : <OldHeader />}
      {children}
      {isNewHeader ? <Footer /> : <OldFooter />}
    </>
  );
}
