import styled from 'styled-components';
import { IGridContainerProps, IGridItemProps } from './Grid.types';
import { DEFAULT_COLUMNS, DEFAULT_GAP, DEFAULT_MARGIN } from './Grid.constants';

export const GridContainer = styled('div')((props: IGridContainerProps) => {
  return {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'repeat(1,1fr);',
    [props.theme.breakpoints.up('lg')]: {
      // desktop
      gap: props.gap ? props.gap : DEFAULT_GAP.lg,
      gridTemplateColumns: `repeat(${props.lg ? props.lg : DEFAULT_COLUMNS.lg}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [props.theme.breakpoints.down('lg')]: {
      // desktop
      gap: props.gap ? props.gap : DEFAULT_GAP.lg,
      gridTemplateColumns: `repeat(${props.lg ? props.lg : DEFAULT_COLUMNS.lg}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [props.theme.breakpoints.down('md')]: {
      // tablet
      gap: props.gap ? props.gap : DEFAULT_GAP.md,
      gridTemplateColumns: `repeat(${props.md ? props.md : DEFAULT_COLUMNS.md}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.md}px`,
    },
    [props.theme.breakpoints.down('sm')]: {
      // mobile
      gap: props.gap ? props.gap : DEFAULT_GAP.sm,
      gridTemplateColumns: `repeat(${props.sm ? props.sm : DEFAULT_COLUMNS.sm}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.sm}px`,
    },
  };
});

export const GridItemContainer = styled('div')((props: IGridItemProps) => {
  return {
    [props.theme.breakpoints.up('lg')]: {
      // desktop
      gridColumn: props.lg ? `span ${props.lg};` : `span ${DEFAULT_COLUMNS.lg};`,
      display: props.lg === 0 ? 'none' : 'flex',
    },
    [props.theme.breakpoints.down('lg')]: {
      // desktop
      gridColumn: props.lg ? `span ${props.lg};` : `span ${DEFAULT_COLUMNS.lg};`,
      display: props.lg === 0 ? 'none' : 'flex',
    },
    [props.theme.breakpoints.down('md')]: {
      // tablet
      gridColumn: props.md ? `span ${props.md};` : `span ${DEFAULT_COLUMNS.md};`,
      display: props.md === 0 ? 'none' : 'flex',
    },
    [props.theme.breakpoints.down('sm')]: {
      // mobile
      gridColumn: props.sm ? `span ${props.sm};` : `span ${DEFAULT_COLUMNS.sm};`,
      display: props.sm === 0 ? 'none' : 'flex',
    },
  };
});
